* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  touch-action: none;
}
a {
  text-decoration: none;
}
.MuiToolbar-root {
  padding-left: 0px !important ;
  padding-right: 0px !important;
}

.MuiDataGrid-row {
  border-bottom: none !important;
}
.css-qnd98-MuiInputBase-root-MuiOutlinedInput-root {
  margin-top: 6.3px !important;
}

.react-tel-input .flag-dropdown {
  width: 100% !important;
}

.react-tel-input .selected-flag {
  width: 100% !important;
}
.react-tel-input ::placeholder {
  color: black;
  opacity: 0.44;
  font: normal normal normal 14px/19px Roboto;
}
.react-tel-input .country-list {
  /* margin: -8px 0 10px 0px !important; */
  /* max-height: 180px !important; */
  border: 0.5px solid #c0c0c0 !important;
  width: auto !important;
}

.react-tel-input .selected-flag .flag {
  position: relative !important;
  top: 0px !important;
  margin-top: 0px !important;
  padding-right: 10px !important;
}

.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: none !important;
  border-radius: 5px;
  width: 100% !important;
  outline: none;
  padding: 0px !important;
  margin: 0px 0px;
  text-align: center;
}

.react-tel-input .selected-flag .arrow {
  display: none;
}

.custom-grid {
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  background-color: #ffff;
  align-self: center;
  justify-content: flex-start;
  font-family: Roboto;
  border-radius: 8px;
  cursor: pointer;
  padding: 0.6rem;
  transition: transform 0.4s;
  width: 250px;
  margin: 1rem;
}
.menu-item-popup:hover {
  background-color: #ebf9f0 !important;
  opacity: 1;
}

.ql-editor.ql-blank::before {
  font-size: 16px !important;
  font-style: normal !important;
  color: rgba(0, 0, 0, 0.38) !important;
}
/* .css-1tiibo2{
  display: contents !important;

} */
.pointer {
  cursor: pointer;
}

.text-transform-none {
  text-transform: none !important;
}

/* .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input{
  font-size: 13px !important;
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important;
} */

.MuiFormControl-root-MuiTextField-root
  .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
  padding: 12px !important;
}

.pac-container {
  z-index: 9999 !important;
}

.customAutocomplete
  .MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 6px;
}

.custom-grid {
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  background-color: #ffff;
  align-self: center;
  justify-content: flex-start;
  font-family: Roboto;
  border-radius: 8px;
  cursor: pointer;
  padding: 0.6rem;
  transition: transform 0.4s;
  width: 342px;
  margin: 1rem;
}

.required {
  color: red;
}

::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
/* 滑块部分 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c8c8c8;
  cursor: pointer;
}
/* 轨道部分 */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c8c8c8;
  background: #ededed;
  border-radius: 5px;
  cursor: pointer;
}

/* .Mui-TableHeadCell-ResizeHandle-Wrapper {
  display: none;
}
.css-yubsjo {
  display: none;
}

.Mui-TableHeadCell-Content-Labels {
  margin: auto;
}

.MuiTableCell-sizeMedium {
  text-align: center;
  border-bottom: none;
} */

/* .css-1jxdcj3-MuiSvgIcon-root,
.css-15veonq-MuiSvgIcon-root,
.css-fxvn92-MuiSvgIcon-root,
.css-qsj5j8-MuiSvgIcon-root,
.MuiPickersCalendarHeader-switchViewIcon,
.css-1ae958b-MuiSvgIcon-root,
.css-lxdvv2-MuiSvgIcon-root-MuiPaginationItem-icon,
.css-1m7o2of-MuiSvgIcon-root {
  width: 0.6rem;
  height: 0.6rem;
}

.css-1jxdcj3-MuiSvgIcon-root {
  width: 0.5rem;
  height: 0.5rem;
}


.css-q3xlek-MuiPaper-root {
  height: 0.9rem;
} */

.MuiTooltip-popper,
.MuiTooltip-tooltip {
  font-size: 0.3rem;
}

.MuiDialogActions-root,
.MuiButton-label {
  font-size: 0.3rem;
}

.MuiSvgIcon-root {
  top: unset
}